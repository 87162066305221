import React from "react";
import PropTypes from "prop-types";

const Logo = props => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      viewBox="0 0 1000 1000"
      enable-background="new 0 0 1000 1000"
    >
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path d="M448.5,3880.3C275.6,3838.5,102.8,3598.7,100,3398c0-147.8,50.2-228.6,610.6-973c387.5-515.8,518.6-711,535.3-794.6c13.9-64.1,139.4-1123.6,278.8-2358.7c184-1628.2,270.4-2283.4,309.5-2386.6c69.7-195.2,242.5-381.9,434.9-476.7l158.9-78.1h2132.9h2132.9l158.9,78.1c192.4,94.8,365.2,281.6,434.9,476.7c39.1,103.2,145,934,393.1,3097.5c186.8,1625.4,337.3,2972.1,337.3,2991.6c0,25.1,119.9,33.5,501.8,33.5h501.9l5.6-1865.2c8.4-1834.5,8.4-1868,66.9-1943.3c108.8-147.8,198-192.4,373.6-192.4s264.9,44.6,373.6,192.4c58.5,75.3,58.5,100.4,58.5,2060.4v1985.1l-69.7,139.4c-86.4,178.4-253.7,345.7-432.1,432.1l-139.4,69.7l-4377.2,2.8C2475.4,3891.5,479.2,3888.7,448.5,3880.3z M7125.9,2993.7c0-8.4-55.8-504.6-125.5-1104.1C6930.7,1290.2,6875,793.9,6875,788.4S5835,777.2,4560.9,777.2c-1271.4,0-2314.1,5.6-2314.1,13.9c0,53-142.2,1232.3-153.3,1260.2c-5.6,19.5-161.7,237-348.5,479.5c-184,245.4-334.6,454.5-334.6,462.8c0,8.3,1285.3,13.9,2857.7,13.9C5840.6,3007.7,7125.9,3002.1,7125.9,2993.7z M5453.1-338v-223h-892.2h-892.2v223v223h892.2h892.2V-338z M5453.1-1676.3v-223h-892.2h-892.2v223v223h892.2h892.2V-1676.3z" />
          <path d="M3668.7,1892.4v-223h892.2h892.2v223v223h-892.2h-892.2V1892.4z" />
        </g>
      </g>
    </svg>
  );
};

Logo.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default Logo;
